.app-select {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70px;

  &__label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--select-main-color);
    padding-left: 20px;
  }

  &__button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-column-gap: 8px;
    text-align: left;
    width: 100%;
    height: 50px;
    border: var(--border) var(--select-main-color);
    border-radius: var(--border-radius-small);
    padding: 15px 20px 15px 20px;
    line-height: 22px;
    color: var(--select-font-color);

    &:disabled {
      background-color: var(--main-disabled-bg-color);
      border: var(--border) var(--main-disabled-bg-color);

      .app-select__button-text {
        color: var(--select-main-color);
      }

      .app-select__button-icon svg path {
        fill: var(--select-main-color);
      }
    }

    &-text {
      pointer-events: none;
    }

    &-icon {
      height: 20px;
      width: 20px;
      pointer-events: none;
      transform: rotate(0);
      transition: .3s ease;

      img {
        height: 100%;
        width: auto;
      }
    }

    &_active &-icon {
      transform: rotate(180deg);
      transition: .3s ease;
    }

    &:disabled {
      cursor: default;
    }

    &.error {
      border: var(--border) var(--main-error-color);
    }
  }

  &__list {
    display: none;
    position: absolute;
    left: 0;
    max-height: 465px;
    overflow-y: auto;
    top: calc(100% + 3px);
    box-sizing: border-box;
    list-style-type: none;
    background-color: var(--select-list-bg-color);
    border-radius: var(--border-radius-small);
    border: var(--border) var(--select-main-color);
    width: 100%;
    margin: 0;
    padding: 0 0 22px 0;
    z-index: 1;

    &_visible {
      display: block;
    }

    &-item {
      position: relative;
      display: flex;
      align-items: center;
      grid-column-gap: 8px;
      margin: 0;
      padding: 18px 22px 10px 22px;
      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 22px;
        right: -22px;
        background-color: var(--select-main-color);
        height: 1px;
        width: calc(100% - 44px);
      }

      &:hover {
        background-color: var(--select-list-hovered-color);
      }

      &_active {
        background-image: var(--select-active-item-checkmark);
        background-position: right 22px top 18px;
        background-repeat: no-repeat;
      }
    }
  }

  &__text-error {
    position: absolute;
    color: var(--input-error-color);
    margin: 2px 0 0 12px;
    font-size: 12px;
    line-height: 1;
    bottom: -15px;
  }
}
