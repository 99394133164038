.user-order {

  &__content {
    border-radius: 15px;
    background-color: var(--main-card-bg-color);
    padding: 50px 88px;
    margin-top: 100px;
    margin-bottom: 100px;

    @media (max-width: 1200px) {
      padding: 28px 20px 50px 20px;
    }

    @media (max-width: 600px) {
      padding: 28px 12px 50px 12px;
      margin-bottom: 70px;
    }

    &-wrapper {
      display: flex;
      justify-content: space-between;

      @media (max-width: 768px) {
        gap: 44px;
      }

      @media (max-width: 370px) {
        gap: 20px;
      }
    }

    &-inner {
      display: flex;
      flex-direction: column;
      grid-row-gap: 10px;

      @media (max-width: 768px) {
        grid-row-gap: 5px;
      }
    }

    &-title, &-price {
      font-size: 24px;
      font-weight: 500;
      line-height: 29px;
      margin: 0;

      @media (max-width: 991px) {
        font-size: 20px;
      }

      @media (max-width: 768px) {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
      }

      @media (max-width: 370px) {
        font-size: 14px;
        line-height: 14px;
      }
    }

    &-time {
      font-size: 16px;
      font-weight: 400;
      color: var(--main-border-color);
      margin: 0;
      line-height: 19px;

      @media (max-width: 370px) {
        font-size: 14px;
      }
    }

    &-container {
      margin: 30px 0 50px;
      display: flex;
      justify-content: space-between;

      @media (max-width: 768px) {
        margin: 30px 0;
      }
    }

    &-address {
      display: flex;
      flex-direction: column;
      grid-row-gap: 5px;

      &-title {
        font-size: 12px;
        line-height: 12px;
        color: var(--main-border-color);
        margin: 0;
      }

      &-subtitle {
        font-weight: 400;
        font-size: 16px;
        color: var(--main-font-color);
        margin: 0;
        line-height: 18px;

        @media (max-width: 370px) {
          font-size: 14px;
        }
      }
    }

    &-detail-order {
      font-weight: 500;
      font-size: 16px;
      color: var(--main-font-color);
      margin: 0;
      line-height: 18px;

      @media (max-width: 370px) {
        margin: 0;
      }
    }
  }

  &__table {

    &-button-mobile {
      display: none;

      &:hover {
        color: var(--main-hover-button-color);
      }

      @media (max-width: 768px) {
        display: block;
        width: fit-content;
        color: var(--order-font-color);
        text-decoration: none;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        margin: 30px auto 0 auto;
      }
    }

    &-button {
      font-size: 16px;
      line-height: 19px;
      color: var(--order-font-color);
      text-decoration: none;
      font-weight: 500;
      height: fit-content;
      margin: auto 0 0 0;

      &:hover {
        color: var(--main-hover-button-color);
      }

      @media (max-width: 768px) {
        display: none;
      }
    }

    &-td {
      display: flex;
      grid-column-gap: 10px;
    }

    .&-td:last-child {
      @media (max-width: 768px) {
        align-items: normal;
      }
    }

    &-tr {
      border-bottom: 1px solid var(--main-border-color);
      display: flex;
      justify-content: space-between;
      padding: 30px 0 10px 6px;

      @media (max-width: 768px) {
        padding: 30px 0 20px 6px;
      }
    }

    &-img div {
      width: 50px;
      height: 50px;
      background-color: var(--main-border-color);

    }

    &-name-count {
      display: flex;
      grid-row-gap: 3px;
      flex-direction: column;
    }

    &-count {
      font-weight: 400;
      font-size: 12px;
      color: var(--main-border-color);
      margin: 0;
      line-height: 14px;
    }

    &-product-name {
      font-size: 12px;
      font-weight: 400;
      color: var(--main-font-color);
      margin: 0;
      line-height: 14px;
    }

    &-price {
      font-weight: 400;
      font-size: 16px;
      color: var(--main-font-color);
      margin: 0;
      line-height: 16px;
    }
  }
}
