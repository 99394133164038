.navbar-body {
    padding: 20px 12px;
    background-color: var(--main-bg-color);
    color: var(--sidebar-item-color);
    display: flex;
    flex-flow: column nowrap;
    overflow-y: auto;
    height: 100%;
    box-sizing: border-box;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        &-title {
            height: 38px;
            font-size: 32px;
            line-height: 38px;
            font-weight: 700;
            overflow: hidden;
            color: var(--main-font-color);
            width: max-content;
            text-decoration: none;

            img {
                height: 100%;
            }
        }

        &-close {
            position: relative;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;

            &::before, &::after {
                content: '';
                position: absolute;
                height: 4px;
                width: 28px;
                top: 50%;
                left: 50%;
                background: var(--main-font-color);
                border-radius: 5px;
            }

            &::before {
                transform: translate(-50%, -50%) rotate(45deg);
            }

            &::after {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }
    }

    &__choose-address {
        margin: 27px 0 0;
        max-width: max-content;

        &-link {
            display: flex;
            gap: 5px;
            font-size: 20px;
            color: var(--main-font-color);
            line-height: 24px;
            font-weight: 500;
            text-decoration: none;
        }
    }

    &__separator-start {
        flex-shrink: 0;
        height: 1px;
        background-color: var(--main-shadow-basic-color);
        margin: 30px 0 20px;
    }

    &__separator-end {
        flex-shrink: 0;
        height: 1px;
        background-color: var(--main-shadow-basic-color);
        margin: 30px 0;
    }

    &__items {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-left: 30px;
    }

    &__item {

        &-link {
            width: fit-content;
            text-decoration: none;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: var(--main-footer-background-color);
            cursor: pointer;

            &:hover {
                font-weight: 700;
            }
        }

        &-points {
            margin: 0;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: var(--main-footer-background-color);
        }

        &-subscript {
            margin: 5px 0 0;
            font-size: 12px;
            font-weight: 300;
            color: var(--main-font-color);
            line-height: 15px
        }

        &-schedule {
            margin: 0;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: var(--main-font-color);
        }
    }

    /* аккордеон Каталога */
    .app-accordion {

        &__btn {
            padding-left: 0;

            &-text {
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: var(--main-font-color);
            }
        }

        &__content {
            padding: 0;
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin: 8px 0 0 0;
        }

        &__link {
            width: fit-content;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: var(--main-font-color);
            text-decoration: none;
            transition: .4s ease;

            &:hover {
                font-weight: 700;
            }
        }
    }
}
