.basket {
    max-width: 365px;
    width: 100%;

    &__title {
        font-weight: 500;
        color: var(--main-font-color);
        font-size: 24px;
        line-height: 29px;
        margin: 0 0 40px;
    }

    &__body {
        display: flex;
        flex-direction: column;
        max-height: 860px;
        overflow-y: auto;

        &-list {
            display: flex;
            flex-direction: column;
            grid-row-gap: 29px;
            padding-right: 10px;

            .basket-item {
                display: flex;
                grid-column-gap: 20px;

                &__text {

                    &_main {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        color: var(--sidebar-main-text-color);
                        margin: 0;
                    }

                    &_secondary {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        color: var(--basket-item-description-color);
                        margin: 0;
                    }
                }

                &__img {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: var(--placeholder-color);
                    height: 88px;
                    width: 88px;
                    border-radius: var(--border-radius-small);

                    img, .dropdown-card__img--none {
                        height: 88px;
                        width: 88px;
                        border-radius: var(--border-radius-small);
                    }
                }

                &__info {
                    display: flex;
                    flex-direction: column;
                    grid-row-gap: 8px;
                    width: 100%;

                    &-header {
                        display: flex;
                        justify-content: space-between;
                        grid-column-gap: 8px;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;

                        &-btn {
                            align-self: flex-start;
                            color: var(--sidebar-main-text-color);
                            cursor: pointer;
                        }
                    }

                    &-modifiers {
                        text-transform: lowercase;
                    }

                    &-result {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        grid-column-gap: 4px;

                        .app-counter {
                            height: 35px;
                        }
                    }
                }
            }
        }
    }
}
