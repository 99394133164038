.account-form {
  display: flex;
  flex-direction: column;
  grid-row-gap: 30px;

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: var(--main-font-color);
    margin: 0 0 0 20px;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: self-start;
    grid-column-gap: 20px;

    .app-input {
      width: 100%;
    }

    &-col {
      display: flex;
      flex-direction: column;
      grid-row-gap: 10px;
    }

    &-birthday {

      .app-input input{
        max-width: 325px;
        min-width: 156px;
        width: 100%;
        text-align: left;
      }
    }
  }

  &__gender {

    &-title {
      font-weight: 400;
      font-size: 16px;
      color: var(--main-font-color);
      line-height: 22px;
      margin: 0 0 10px 20px;
    }

    &-radio {
      display: flex;
      grid-column-gap: 20px;
    }
  }

  &__phone {

    &-title {
      font-weight: 700;
      font-size: 18px;
      color: var(--main-font-color);
      line-height: 22px;
      margin: 0 0 10px 20px;
    }

    .app-input {
      position: relative;

      img {
        position: absolute;
        right: 20px;
        bottom: 14px;
        width: 20px;
        height: 20px;
      }
    }

  }

  .app-accordion {

    &__btn-text {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      margin-left: 20px;
    }

    &__content {
      padding-left: 0;
    }
  }

  &__send-form {
    display: flex;
    align-self: flex-end;
    grid-column-gap: 3px;
    max-width: 326px;
    width: 100%;
    position: relative;
    height: 75px;

    .app-button {
      height: 50px;
      width: 100%;
    }

    &-status {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      animation: fadeEffect 1.5s;
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      &_success {
        color: var(--main-approve-color);
      }
    }
  }

  &__card-create {
    border-radius: var(--border-radius-small);
    padding: 20px 10px;
    border: 2px solid var(--main-border-color);
    width: 100%;
    height: 100px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: var(--main-font-color);

    &:hover {
      border-color: var(--main-approve-color);
    }
  }

  &__btn {

    &-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 30px;
      margin: 30px 0;
    }
  }
}

@media (max-width: 1200px) {

  .account-form {

    &__btn {

      &-wrapper {
        grid-template-columns: repeat(2, 1fr);
        justify-items: stretch;
        grid-column-gap: 15px;
        grid-row-gap: 25px;
      }
    }
  }
}

@media (max-width: 768px) {

  .account-form {

    &__wrapper {
      grid-template-columns: repeat(1,1fr);
      grid-row-gap: 10px;
    }
  }
}

@media (max-width: 470px) {

  .account-form {

    &__btn {

      &-wrapper {
        grid-template-columns: repeat(1, 1fr);
        justify-items: stretch;

        .app-card-button__btn-header-title {
          width: 250px;
        }
      }
    }
  }
}

@media (max-width: 390px) {

  .account-form {

    .app-card-button {

      &__btn-header-title {
        width: 110px;
      }
    }
  }
}

@media (max-width: 320px) {

  .account-form {

    &__wrapper {

      .app-input {

        &__label {
          font-size: 12px;
        }
      }
    }
  }
}
