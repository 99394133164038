.delivery-and-payment {

  &__title {
    font-weight: 700;
    font-size: 32px;
    color: var(--main-font-color);
    line-height: 39px;
    margin: 0 0 30px;
  }

  &__inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    .delivery-and-payment {

      &__card {
        grid-column-start: 1;
        grid-column-end: 2;
        padding: 30px;
        height: fit-content;
        background: var(--main-card-bg-color);
        border-radius: var(--border-radius-small);

        &-title {
          font-weight: 700;
          font-size: 24px;
          color: var(--main-font-color);
          line-height: 29px;
          margin: 0 0 16px;
        }

        &-description {
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          color: var(--main-font-color);
          margin: 0;

          p {
            color: var(--main-font-color);
            font-size: 16px;
            line-height: 19px;
            font-weight: 400;
          }
        }

        p, em {
          margin: 0;
        }

        em {
          font-style: inherit;
          color: var(--main-border-color);
          margin-bottom: 10px;
        }
      }

      &__map {
        grid-row-start: 1;
        grid-row-end: 3;
        grid-column-start: 2;
        grid-column-end: 3;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;

        iframe {
          border: none;
        }
      }
    }
  }

  @media (max-width: 1000px) {

    &__inner {
      display: flex;
      flex-direction: column;
    }

    &__map {
      height: 400px;
    }
  }

  @media (max-width: 768px) {

    .delivery-and-payment__card {
      padding: 16px;
    }
  }
}
