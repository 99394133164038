.feedback {

  &__title {
    font-size: 32px;
    font-weight: 700;
    margin: 0;
    line-height: 39px;
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 50px 0 100px;
    gap: 85px;
  }

  &__wrapper-form, &__wrapper-form-skeleton {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    max-width: 554px;
    width: 100%;
    border-radius: 15px;
  }

  &__form, &__form-skeleton {
    display: flex;
    flex-flow: column nowrap;
    background: var(--card-bg-color);
    border-radius: 15px;
    box-sizing: border-box;
    padding: 50px;
    width: 100%;

    &-title {
      font-size: 24px;
      font-weight: 500;
      line-height: 29px;
      color: var(--main-font-color);
      margin: 0 0 10px 20px;
    }

    .app-input {
      margin-bottom: 20px;
    }

    .app-button {
      max-width: 325px;
      min-height: 50px;
      width: 100%;
      margin-top: 30px;
      margin-left: auto;
    }

    .app-textarea {

      &__label {
        white-space: unset;
      }
    }
  }

  @media (max-width: 1440px) {

    &__form {
      width: 100%;
    }
  }

  @media (max-width: 1200px) {

    &__wrapper {
      flex-direction: column;
      align-items: center;
    }

    &__wrapper-form-skeleton {
      height: 600px;
    }

    &__img {
      display: none;
    }
  }

  @media (max-width: 768px) {

    &__title {
      font-size: 24px;
      line-height: 29px;
      margin-top: 30px;
    }

    &__wrapper {
      margin: 30px 0 70px;
    }

    &__form {
      padding: 30px 20px 50px;

      .app-button {
        max-width: none;
      }
    }
  }

  @media (max-width: 480px) {

    &__form {
      min-width: 250px;
    }
  }
}
