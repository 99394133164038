.app-button {
    background-color: var(--button-bg-color);
    color: var(--button-font-color);
    border-radius: var(--border-radius-large);
    box-sizing: border-box;
    border: var(--border) var(--button-bg-color);
    padding: 10px 8px;
    font-size: 14px;
    transition: .3s ease;

    &:hover {
        background-color: var(--button-ng-hovered-color);
        color: var(--button-font-hovered-color);
    }

    &:active {
        background-color: var(--button-bg-active-color);
        color: var(--button-font-active-color);
    }

    &:disabled {
        border-color: var(--button-bg-disabled-color);
        background-color: var(--button-bg-disabled-color);
        color: var(--button-font-disabled-color);
        cursor: default;
    }
}
