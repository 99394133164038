.app-checkbox {
  display: flex;
  align-items: center;
  grid-column-gap: 8px;
  cursor: pointer;
  color: var(--main-font-color);
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;

  &__input {

    &_real {
      position: absolute;
      width: 0;
      height: 0;
      opacity: 0;
      z-index: -1;
    }

    &_custom {
      position: relative;
      width: 18px;
      height: 18px;
      border-radius: 4px;
      border: 1px solid #cccfdb;
      transition: all .2s ease;
      box-shadow: 0 1px 1px rgba(0, 16, 75, 0.05);

      svg {
        position: absolute;
        top: 1px;
        left: 0;
        right: 1px;
        bottom: 0;
        margin: auto;
        overflow: visible;
        height: 11px;
        width: 11px;
        fill: none;
        stroke: #fff;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 16px;
        stroke-dashoffset: 16px;
        transition: all .3s ease;
      }
    }

    &_real:checked + &_custom {
      background: var(--button-bg-color);
      border-color: var(--button-bg-color);

      svg {
        stroke-dashoffset: 0;
      }
    }

    /* TODO: добавить disabled, focus */
  }
}
