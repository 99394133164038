.approve-modal {

  .modal__body {
    padding: 50px 46px;
    margin: 20px;
  }

  &__text {
    color: var(--main-font-color);
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }

  &__btns {
    display: flex;
    justify-content: center;
    grid-column-gap: 30px;
    margin-top: 20px;

    .app-button {
      height: 50px;
      max-width: 110px;
      width: 100%;
    }
  }
}

@media (max-width: 414px) {

  .approve-modal {

    .modal__body {
      width: 100%;
      border-radius: 15px 15px 0 0;
      margin: 0;

      .chevron {
        left: 10px;
      }
    }
  }
}
