.profile-bonus {

  &__content {
    display: flex;
    flex-direction: column;

    &-img {
      width: 100px;
      height: 100px;
    }

    &-text{
      font-size: 20px;
      color: var(--main-border-color);
      margin: 10px 0 0 0;
    }
  }

  /* Заголовок */
  &__wrapper {
    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
  }
  &__company {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    color: var(--main-font-color);
    margin: 0;

    @media (max-width: 768px) {
      font-size: 32px;
      line-height: 39px;
    }
  }

  &__wrap-bonus-count {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__bonus {
    &-count {
      border-radius: 25px;
      background-color: var(--bonus-font-secondary-color);
      color: var(--main-font-secondary-color);
      font-size: 20px;
      padding: 8px 16px;
      line-height: 24px;
      min-width: 76px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
    }

    &-count-text {
      color: var(--main-font-color);
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
      margin: 0;
    }
  }

  /* таблица */
  &__table {
    color: var(--main-font-color);

    &-content {
      color: var(--main-border-color);
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: var(--main-font-color);
      margin: 0 0 30px;
    }

    &_empty {
      margin: 100px auto;
    }

    &-time {
      font-size: 16px;
    }

    &-status {
      font-size: 14px;
    }

    &-time, &-status {
      line-height: 24px;
      color: var(--main-border-color);
      margin: 0;
    }

    &-bonus {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: var(--bonus-font-secondary-color);
      margin: 10px 0 0 auto;
      width: fit-content;
    }

    &-tr {
      border-bottom: 1px solid var(--main-border-color);
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      grid-row-gap: 30px;
      padding: 30px 6px 10px 6px;

      @media (max-width: 768px) {
        flex-direction: column;
        padding: 30px 0 20px 0;
      }
    }

    &-number, &-sum {
      line-height: 24px;
      font-weight: 700;
      margin: 0;
    }

    &-img {
      display: flex;
      flex-wrap: wrap;
      margin-top: 12px;
      grid-gap: 10px;

      div {
        width: 50px;
        height: 50px;
        background-color: var(--main-footer-background-color);
      }
    }

    &-row {
      display: flex;
      flex-direction: column;
      grid-row-gap: 5px;

      &_ended {
        align-items: flex-end;

        @media (max-width: 768px) {
          align-items: flex-start;
          flex-direction: row-reverse;
          justify-content: space-between;
        }
      }
    }
  }

  /* Карточки */
  &__cards {
    display: flex;
    flex-flow: wrap;
    grid-row-gap: 30px;
    grid-column-gap: 20px;
    margin: 30px auto 50px;
    max-width: 670px;
    justify-content: center;

    @media (max-width: 768px) {
      margin: 19px 0 50px;
    }
  }

  &__card {
    border: 1px solid var(--main-footer-background-color);
    background-color: var(--main-card-bg-color);
    border-radius: 15px;
    padding: 20px;
    max-width: 325px;
    min-height: 249px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;

    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-title {
      font-size: 24px;
      font-weight: 400;
      color: var(--main-font-color);
      line-height: 29px;
      margin: 0;

      @media (max-width: 768px) {
        font-size: 20px;
      }
    }

    &-percent {
      font-weight: 700;
      font-size: 24px;
      padding: 20px 0;
      line-height: 29px;
      margin: 0;
      color: var(--main-font-color);

      @media (max-width: 1200px) {
        padding: 0;
      }
    }

    &-img {
      width: 100px;
      height: 70px;
      display: flex;
      justify-content: flex-end;
      overflow: hidden;

      img {
        height: 100%;
      }
    }

    &-description {
      padding-bottom: 15px;
      font-weight: 400;
      font-size: 16px;
      margin: 0;
      line-height: 19px;
      color: var(--main-font-color);
    }
  }
}
