.app-counter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column-gap: 2px;
  height: 35px;
  border: var(--border) var(--counter-border-color);
  border-radius: 30px;
  box-sizing: border-box;
  overflow: hidden;

  &__button {
    position: relative;
    display: flex;
    width: 34px;
    height: 100%;
    transition: .3s ease-in-out;

    &_negative {
      border-radius: var(--border-radius-medium) 0 0 var(--border-radius-medium);
      position: relative;

      &:before {
        content: '';
        position: absolute;
        inset: 0;
        margin: auto;
        width: 16px;
        height: 2px;
        background-color: var(--counter-default-color);
      }
    }

    &_positive {
      border-radius: 0 var(--border-radius-medium) var(--border-radius-medium) 0;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        inset: 0;
        margin: auto;
        width: 16px;
        height: 2px;
        background-color: var(--counter-default-color);
      }

      &:after {
        content: '';
        position: absolute;
        inset: 0;
        margin: auto;
        width: 2px;
        height: 16px;
        background-color: var(--counter-default-color);
      }
    }

    &:hover {
      background-color: var(--counter-default-color);
    }

    &:active {
      background-color: var(--counter-active-color);
    }

    &:hover, &:active {

      &.app-counter__button_negative:before,
      &.app-counter__button_positive:after,
      &.app-counter__button_positive:before {
        background-color: var(--counter-icon-hovered-color);
      }
    }
  }

  &__count {
    width: 28px;
    text-align: center;
    font-weight: 500;
    line-height: 22px;
    color: var(--counter-border-color);
  }
}
