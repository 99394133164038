.header {
    width: 100%;
    z-index: 1;
    background: var(--main-bg-color);
    box-shadow: var(--shadow);
    padding-bottom: 10px;

    @media (max-width: 768px) {
        padding: 12px 0;
        background: var(--mobile-header-bg-color);
    }
}

.nav {
    display: flex;
    flex-flow: column nowrap;
    font-weight: normal;
    line-height: 26px;
    font-size: 16px;
    letter-spacing: 0.5px;


    &__upper {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
        padding-bottom: 20px;

        @media (max-width: 768px) {
            display: none;
        }
    }

    &__left-col {
        display: flex;
        flex-direction: column;
        grid-row-gap: 10px;
    }

    &__right-col {
        display: flex;
        align-items: flex-end;
        grid-column-gap: 21px;

        &_vertical {
            flex-direction: column;
            grid-row-gap: 4px;
        }
    }

    &__company-logo {
        text-decoration: none;

        &-img {
            height: 34px;
        }
    }

    &__company-name {
        text-decoration: none;
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
        color: var(--main-font-color);
        width: fit-content;
    }

    &__address-schedule {
        display: flex;
        grid-column-gap: 10px;
    }

    &__address {
        font-weight: 500;
        color: var(--main-border-color);
        font-size: 14px;
        line-height: 15px;
        width: fit-content;
        padding: 0;

        &:hover {
            color: var(--button-font-hovered-color);
        }
    }

    &__address-button__loader {
        border-radius: var(--border-radius-small);
        width: 100px;
        height: 16px;
    }

    &__schedule {
        font-weight: 500;
        color: var(--main-border-color);
        font-size: 14px;
        line-height: 15px;
        width: fit-content;
        margin: 0;
        display: flex;
        grid-column-gap: 5px;

        &-icon {
            height: 15px;
        }
    }

    &__phone {
        font-size: 16px;
        line-height: 19px;
        color: var(--main-font-color);
        font-weight: 400;
        text-decoration: none;
    }

    &__profile-link, &__bonus-link {
        text-decoration: none;
    }

    &__profile-button, &__bonus-button {
        display: flex;
        align-items: center;
        flex-direction: column;
        color: var(--main-font-color);
        font-weight: 500;
        line-height: 19px;
        font-size: 16px;
        padding: 0;
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }

    &__block--justify-to-start {
        display: flex;
        align-items: center;
        grid-column-gap: 30px;
    }

    &__profile-button__loader, &__bonus-button__loader {
        width: 110px;
        height: 40px;
        border-radius: var(--border-radius-medium);
    }

    &__block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-column-gap: 20px;
        text-transform: none;
    }

    &__link {
        text-decoration: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: var(--main-font-color);

        &:hover,
        &:active {
            text-decoration: underline;
        }
    }

    &__link-category {
        text-decoration: none;
        font-weight: 400;
        font-size: 14px;
        color: var(--main-font-color);

        &.active {
            font-weight: 700;
        }

        &:hover,
        &:active {
            text-decoration: underline;
        }
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &_sticky {
        position: sticky;
        top: -1px;
        z-index: 20;
        backdrop-filter: blur(18px);
        background-color: var(--header-nav-bg-color);

        .nav__actions {
            overflow: hidden;
            height: 56px;

            &_moving-block {
                display: flex;
                align-items: center;
                height: 36px;
                transition: ease .3s;
            }
        }

        .nav__logo {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 36px;
            margin-right: 20px;
            cursor: pointer;

            img {
                height: 100%;
            }

            h1 {
                margin: 0;
                font-size: 24px;
                line-height: 1px;
                font-weight: 700;
                color: var(--main-font-color);
            }
        }
    }

    &__loader-cart {
        width: 152px;
        height: 40px;
        border-radius: var(--border-radius-medium);
    }
    
    .app-button {
        min-width: 152px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
    }

    @media (max-width: 768px) {
        display: none;
    }

}

.nav-mobile {
    display: none;

    @media (max-width: 768px) {
        position: fixed;
        width: calc(100% - 24px);
        height: 69px;
        padding: 0 12px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        background-color: var(--mobile-header-bg-color);
        backdrop-filter: blur(18px);
        top: 0;

        &__company {
            display: flex;
        }

        &__logo {
            text-decoration: none;
        }

        &__link {
            text-decoration: none;
            color: var(--mobile-header-item-color);
            font-size: 32px;
            line-height: 1.2;
            font-weight: 700;
        }

        &__sidebar-button {
            background: none;
            border: none;
            color: var(--mobile-header-item-color);
            outline: none;
            margin-left: 15px;
        }

        &__button--back {
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: rotate(90deg);
        }
    }

    @media (max-width: 575px) {

        &__sidebar-button {
            margin-left: 20px;
            padding: 0;
        }
    }
}
