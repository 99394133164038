.app-card-button {
  position: relative;

  &__btn {
    border-radius: var(--border-radius-small);
    padding: 10px 10px 10px 20px;
    border: var(--border-bold) var(--main-border-color);
    min-width: 210px;
    width: 100%;
    height: 100px;
    box-sizing: border-box;
    display: grid;

    &:hover, &_active {
      border: var(--border-bold) var(--main-approve-color);
    }

    &-status {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      position: absolute;
      bottom: -20px;
      text-align: center;
      width: 100%;
      opacity: 0;

      &_success {
        color: var(--main-border-color);
        opacity: 1;
      }
    }

    &-header {
      display: flex;
      justify-content: space-between;
      grid-column-gap: 10px;
      align-items: center;

      &-title {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        color: var(--main-font-color);
        overflow: hidden;
        text-overflow: ellipsis;
        width: 110px;
        margin-left: 0;
        text-align: left;
      }
    }

    &-description {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: var(--main-font-color);
      margin: 0;
      justify-self: flex-start;
      align-self: end;
    }

    &-control {
      display: flex;
      grid-column-gap: 5px;
      position: relative;

      &-edit {
        display: flex;
        justify-content: center;
        cursor: pointer;
        padding: 0;
      }

      &-delete {
        width: 20px;
        height: 20px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        padding: 0;

        &::before, &::after {
          content: '';
          position: absolute;
          top: 10px;
          display: block;
          width: 15px;
          border-radius: var(--border-radius-small);
          height: 2px;
          background: var(--main-font-color);
        }

        &::before {
          transform: rotate(45deg);
        }

        &::after {
          transform: rotate(-45deg);
        }
      }
    }
  }
}
