/*  profile  */
.profile {

  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: var(--profile-main-font-color);
    margin: 0 0 30px 0;
  }

  &__body {
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 30px;

    @media (max-width: 1200px) {
      grid-template-columns: 150px auto;
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    &-skeleton {
      border-radius: var(--border-radius-small);
      height: 848px;
      max-width: 850px;
      box-sizing: border-box;

      @media (max-width: 1200px) {
        max-width: 710px;
      }
    }
  }

  &__navigation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-row-gap: 10px;
    padding-top: 50px;

    @media (max-width: 1200px) {
      padding-top: 28px;
    }

    @media (max-width: 768px) {
      padding-top: 0;
    }

    &-tab, &-btn {
      color: var(--main-font-color);
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      padding: 0;
      transition: ease .1s;
      text-decoration: none;

      &_active, &:hover {
        font-weight: 700;
        color: var(--profile-active-link);
      }
    }
  }

  &__content {
    background-color: var(--profile-bg-content);
    border-radius: var(--border-radius-small);
    min-height: 110px;
    padding: 50px 88px;
    max-width: 850px;
    box-sizing: border-box;

    @media (max-width: 1200px) {
      padding: 28px 20px 50px 20px;
      max-width: 710px;
    }

    @media (max-width: 600px) {
      padding: 28px 12px 50px 12px;
    }

    &-item {
      display: none;
      animation: fadeEffect 1s;
    }
  }
}

/* модалка в профиле */

.access-modal {
  position: fixed;
  inset: 0;
  z-index: 25;
  display: none;
}

.access-modal__background {
  background: var(--main-overlay-color);
  position: fixed;
  inset: 0;
  display: none;
  cursor: pointer;
}

.access-modal__container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 40px;
  z-index: 100;
}

.access-modal__content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--main-card-bg-color);
  border-radius: 15px;
  padding: 50px;

  @media (max-width: 768px) {
    padding: 30px;
  }
}

.access-modal__group-btns {
  display: flex;
  margin-top: 20px;
  grid-column-gap: 30px;

  .app-button {
    width: 100px;
  }
}

.access-modal__text {
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  line-height:21px;
}


.access-modal__close-style {
  position: absolute;
  top: 15px;
  right: -15px;
  cursor: pointer;

  &::before, &::after {
    content: '';
    position: absolute;
    height: 4px;
    width: 25px;
    top: 50%;
    left: 0;
    background: var(--main-secondary-color);
    border-radius: 5px;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  @media (max-width: 768px) {
    top: -20px;
    right: 25px;
  }
}

