.user-orders {

  &__content {
    display: flex;
    flex-direction: column;
    min-height: 400px;

    &-title {
      font-weight: 500;
      line-height: 29px;
      margin: 0 0 30px;
      font-size: 24px;
    }

    &-text {
      font-size: 20px;
      color: var(--main-border-color);
      margin: 10px 0 0 0;
    }

    &-img {
      width: 100px;
      height: 100px;
    }
  }

  &__table {
    color: var(--main-font-color);

    &_empty {
      margin: auto;
    }

    &-time {
      font-size: 16px;
    }

    &-status {
      font-size: 14px;
    }

    &-time, &-status {
      line-height: 24px;
      color: var(--main-border-color);
      margin: 0;
    }

    &-tr {
      border-bottom: 1px solid var(--main-border-color);
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      grid-row-gap: 30px;
      padding: 6px 6px 10px 6px;
      transition: .4s ease;

      @media (max-width: 768px) {
        flex-direction: column;
        padding-bottom: 30px;
      }

      &:hover {
        background-color: var(--order-hover-color);
        border-radius: 15px;
      }
    }

    &-number, &-sum {
      line-height: 24px;
      font-weight: 700;
      margin: 0;
    }

    &-img {
      display: flex;
      flex-wrap: wrap;
      margin-top: 12px;
      grid-gap: 10px;

      div {
        width: 50px;
        height: 50px;
        background-color: var(--main-footer-background-color);
      }
    }

    &-button {
      display: block;
      margin-top: 12px;
      color: var(--order-font-color);
      text-decoration: none;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;

      @media (max-width: 768px) {
        margin: 20px auto 0 auto;
        width: fit-content;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
      }
    }

    &-row {
      display: flex;
      flex-direction: column;
      grid-row-gap: 5px;

      &_ended {
        align-items: flex-end;

        @media (max-width: 768px) {
          align-items: flex-start;
          flex-direction: row-reverse;
          justify-content: space-between;
        }
      }
    }

    &-content {
      color: var(--main-border-color);
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
