.sidebar {
    position: fixed;
    z-index: 51;

    &.open {
        inset: 0;

        .sidebar__overlay {
            height: 100%;
            width: 100%;
            background: var(--main-overlay-color);
        }

        .sidebar__inner {
            transform: translateX(0);
        }
    }

    &__overlay {
        cursor: pointer;
        transition: .3s ease;
        background-color: transparent;
        z-index: -1;
    }

    &__inner {
        max-width: 400px;
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        transform: translateX(-100vw);
        transition: .6s ease;

        &.right {
            left: auto;
            right: 0;
            transform: translateX(100vw);
        }
    }
}
