.app-loader {
    position: relative;
    width: 30px;
    height: 30px;
    animation: rotate 0.7s linear infinite;

    &__circle {
        width: 100%;
        height: 100%;
        fill: none;
        stroke-width: 3;
        stroke: var(--button-font-disabled-color);
        stroke-linecap: round;
        stroke-dasharray: 16;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
