.app-auth-code {
    display: flex;
    flex-direction: column;
    grid-row-gap: 8px;

    &__list {
        display: flex;
        grid-column-gap: 10px;

        &-input {
            width: 50px;
            border-radius: 15px;
            height: 50px;
            background-color: transparent;
            border: var(--border) var(--input-default-color);
            text-align: center;
            font-size: 16px;
            line-height: 19px;
            font-weight: 400;

            &:focus {
                border: var(--border) var(--input-active-color);
            }

            &:focus-visible {
                outline: var(--border) var(--input-active-color);
            }

            &_error {
                margin: 0;
                color: var(--input-error-color);
                border: var(--border) var(--input-error-color);

                &:focus {
                    border: var(--border) var(--input-error-color);
                }

                &:focus-visible {
                    outline: var(--border) var(--input-error-color);
                }
            }
        }
    }

    &__error {
        color: var(--input-error-color);
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        margin: 0;
    }
}
