.address-modal {

  .modal {

    &__body {
      max-width: 1000px;
      width: 100%;

      &-container_scroll {
        padding: 20px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    grid-row-gap: 30px;
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: var(--main-font-color);
    margin: 0;
  }

  &__radio {
    display: flex;
    grid-column-gap: 50px;
  }

  &__input-button {
    display: flex;
    grid-column-gap: 20px;
    align-items: end;

    .app-button {
      height: 50px;
      width: 100%;
      max-width: 305px;
    }

    .app-input {
      width: 100%;
      position: relative;
    }

    .app-select {
      width: 100%;
    }
  }

  &__map {
    width: 100%;
    height: 460px;

    iframe {
      height: 100%;
      border-radius: var(--border-radius-small);
      border: none;
    }
  }
}

@media (max-width: 1100px) {

  .address-modal {

    .modal {

      &__body {
        max-width: 700px;
      }
    }
  }
}

@media (max-width: 768px) {

  .address-modal {


    &__input-button {
      flex-direction: column;
      grid-row-gap: 20px;

      .app-button {
        max-width: unset;
      }
    }

    &__content {
      grid-row-gap: 20px;
    }

    .modal__body {
      margin: 0;
      border-radius: 15px 15px 0 0;
      max-width: unset;

      &-container_scroll {
        padding: 50px 30px 30px;
      }

      .chevron {
        top: 10px;
        z-index: 2;
      }
    }
  }
}
