.app-radio-tape {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: var(--modal-card-bg-color);
    overflow: hidden;

    &__input {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        cursor: pointer;
        user-select: none;
        padding: 6px;
        box-sizing: border-box;
        color: var(--main-font-color);

        &.active {
            background-color: var(--main-active-button-color);
            color: var(--main-font-secondary-color);
            transition: .8s ease;
        }

        &_real {
            width: 0;
            height: 0;
            position: absolute;
            opacity: 0;
            z-index: -1;
        }

        &_custom {
            font-size: 12px;
            line-height: 1.2;
        }
    }
}
