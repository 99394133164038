.data-removal-modal {
  padding: 20px;

  .modal__body {
    padding: 50px 46px;
    margin: 20px;
  }

  &__btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .app-button {
      height: 50px;
      width: 100%;
    }
  }
}

@media (max-width: 414px) {

  .data-removal-modal {

    .modal__body {
      width: 100%;
      border-radius: 15px 15px 0 0;
      margin: 0;

      .chevron {
        left: 10px;
      }
    }
  }
}
