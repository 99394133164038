.app-textarea {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 70px;
  width: 100%;

  textarea {
    width: 100%;
    height: 100px;
    padding: 15px 8px 16px 20px;
    border: var(--border) var(--input-default-color);
    background-color: transparent;
    border-radius: var(--border-radius-small);
    outline: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    transition: 0.5s ease;
    box-sizing: border-box;
    margin-top: 20px;
    resize: none;

    &:focus {
      border: var(--border) var(--input-active-color);
    }
  }

  &__label {
    position: absolute;
    left: 0;
    top: 20px;
    padding: 15px 8px 16px 20px;
    transition: 0.5s ease;
    pointer-events: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    white-space: nowrap;
    width: fit-content;

    &.active {
      top: 0;
      color: var(--input-default-color);
      transform: translateX(20px);
      padding: 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
    }
  }

  &.error {

    textarea {
      border: var(--border) var(--input-error-color);
    }

    .app-textarea__error {
      color: var(--input-error-color);
    }
  }

  &.disabled {

    textarea {
      background-color: var(--input-disabled-color);
      border: none;
    }

    .app-textarea__label {
      color: var(--input-disabled-inner-color);
    }
  }
}
