.product-modal {

    &__content {
        display: grid;
        grid-template-columns: repeat(2, 490px);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
    }

    .modal {

        &__body {
            max-width: 1060px;
            box-sizing: border-box;
        }
    }

    &__accordions {
        display: flex;
        flex-direction: column;
        grid-row-gap: 10px;
    }

    &__body {
        position: relative;
        display: flex;
        flex-direction: column;
        grid-row-gap: 20px;
        padding: 0 4px;

        &-title {
            font-weight: 700;
            color: var(--main-font-color);
            font-size: 24px;
            line-height: 36px;
            margin: 0;
        }

        &-desc {
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            color: var(--main-font-color);
            margin: 20px 0 0;
        }

        .app-button {
            height: 40px;
            min-width: 260px;
            width: 100%;
            margin-top: auto;
        }
    }

    .app-checkbox {
        width: fit-content;
    }

    .app-radio {
        width: fit-content;
        line-height: 19px;

        &__input_custom {
            height: 20px;
            width: 20px;

            &:before {
                height: 12px;
                width: 12px;
            }
        }
    }

    .tapes-list {
        display: flex;
        flex-direction: column;
        grid-row-gap: 12px;
        margin-top: 12px;
    }

    @media (max-width: 1200px) {

        &__content {
            grid-template-columns: repeat(1, 490px);
        }
    }

    @media (max-width: 768px) {

        &__content {
            grid-template-columns: repeat(1, 400px);
        }

        .modal__body {

            .cross {
                display: flex;
            }
        }
    }

    @media (max-width: 540px) {

        .modal__body {
            width: 100%;
        }

        &__content {
            grid-template-columns: repeat(1, 1fr);
        }

        &__slider {
            border-radius: 0;
        }

        &__container-img {
            width: 100%;
            height: 320px;
        }
    }

    @media (min-width: 769px) {

        .modal__body-container_scroll {
            padding: 20px;
        }
    }
}

