.app-spinner {
    width: 48px;
    height: 48px;
    border: 5px solid var(--main-bg-color);
    border-bottom-color: var(--main-active-button-color);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
