.checkout {
    display: flex;
    justify-content: space-between;
    grid-column-gap: 70px;
    margin-top: 28px;
    margin-bottom: 100px;

    &__skeleton {
        width: 100%;
        height: 800px;
        border-radius: var(--border-radius-small);
    }

    @media (max-width: 1200px) {

        .client-data {

            .client-data__form {
                margin: 0;

                &-submit {
                    text-align: center;
                }
            }
        }

        .basket-price {
            flex-direction: column;
        }

        .basket {
            display: none;
        }
    }
}
