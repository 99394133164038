.app-radio {
    line-height: 22px;
    display: flex;
    align-items: center;
    grid-column-gap: 7px;
    cursor: pointer;
    color: var(--main-font-color);
    font-size: 16px;
    font-weight: 400;

    &__input {

        &_real {
            width: 0;
            height: 0;
            position: absolute;
            opacity: 0;
            z-index: -1;
        }

        &_custom {
            position: relative;
            display: inline-block;
            width: 24px;
            height: 24px;
            border: var(--border) var(--input-radio-default-color);
            border-radius: 50%;
            box-sizing: border-box;

            &:before {
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                display: inline-block;
                width: 16px;
                height: 16px;
                background-color: var(--input-radio-default-color);
                border-radius: 50%;
                transform: translate(-50%, -50%) scale(0);
                transition: .2s ease-in;
            }
        }

        &_real:checked + &_custom {
            border: var(--border) var(--input-radio-active-color);

            &:before {
                transform: translate(-50%, -50%) scale(1);
                background-color: var(--input-radio-active-color);
            }
        }

        &_real:disabled + &_custom {
            border: var(--border) var(--input-radio-default-color);
            background-color: var(--input-radio-disabled-color);

            &:before {
                background-color: var(--input-radio-default-color);
            }
        }

        &_real:not(:disabled):hover + &_custom {
            border: var(--border) var(--input-radio-active-color);
            transition: .2s ease-in;
        }
    }
}
